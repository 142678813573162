import React from "react";
import product2 from "../../../assets/images/product2.png";

function Card({ data, title }) {
  return (
    <div className="card">
      <img
        loading="lazy"
        width={"100%"}
        height={"100%"}
        style={{ minHeight: "325px", maxHeight: "325px" }}
        src={data?.imgUrl}
        alt=""
      />
      <div className="card__content">
        <p className="card__title" style={{ fontSize: "13px" }}>
          {title}
        </p>

        {/* {data?.title && (
          <p className="card__title1 card_data">
            Product Name : <span className="card_value">{data?.title}</span>
          </p>
        )} */}
        {data?.title && (
          <p className="card__title1 card_data">
            <span className="card__title">{data?.title}</span>
          </p>
        )}
        <div className="divider"></div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div>
            {data?.weight && (
              <p className="card__title1 card_data">
                Weight: <span className="card_value">{data?.weight}</span>
              </p>
            )}
            {data?.deniar && (
              <p className="card__title1 card_data">
                Denier : <span className="card_value">{data?.deniar}</span>
              </p>
            )}

            {data?.material && (
              <p className="card__title1 card_data">
                Material : <span className="card_value">{data?.material}</span>
              </p>
            )}
          </div>
          <div>
            <img
              style={{
                minHeight: "115px",
                maxHeight: "115px",
              }}
              src={data?.imgUrl}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
